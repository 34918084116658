
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  ref,
} from "vue";
import CommonFrom from "@/components/renderComponents/commonFrom/CommonFrom.vue";
import { mainServer } from "@/apiServer/installServer";
export default defineComponent({
  name: "DemoView",
  components: {
    CommonFrom,
  },
  setup() {
    const { proxy }: any = getCurrentInstance();
    const refsCommonFrom: any = ref(null);
    let data: any = reactive({
      commonFromConfig: [
        [
          {
            span: 24,
            type: "input",
            BindingValues: "system_app_name",
            BindingLabels: "应用名称",
            placeholder: "请输入应用名称",
            rules: [
              {
                required: true,
                message: "请输入应用名称",
                trigger: "blur",
              },
              {
                validator: (rule: any, value: any, callback: any) => {
                  const params: any = {
                    system_app_name: value,
                  };
                  mainServer.VerifyAppApi(params, false).then((res: any) => {
                    console.log(res);
                    if (res.data.stateMsg === "success") {
                      callback();
                    } else {
                      callback(new Error(res.data.data.res));
                    }
                  });
                },
                trigger: "blur",
              },
            ],
            annotation: "应用名称，是您创建和查找应用关键信息",
            FnBlur: FnApplicationNameBlur,
          },
        ],
        [
          {
            span: 24,
            type: "input",
            BindingValues: "system_app_password",
            BindingLabels: "上传口令",
            placeholder: "请输入上传口令",
            rules: [
              {
                required: true,
                message: "请输入上传口令",
                trigger: "blur",
              },
            ],
            annotation: "上传口令是用于您使用上传接口上传附件时上传凭证",
            FnBlur: FnApplicationNameBlur,
          },
        ],
        [
          {
            span: 24,
            type: "textarea",
            BindingValues: "system_app_describe",
            BindingLabels: "应用描述",
            placeholder: "请书写应用描述",
            row: 5,
            rules: [
              {
                required: true,
                message: "请填写应用描述",
                trigger: "blur",
              },
            ],
            annotation: "请填写此应用的用途及其他描述性信息",
          },
        ],
        [
          {
            span: 24,
            type: "inputAppend",
            BindingValues: "system_app_limit",
            BindingLabels: "上传大小",
            placeholder: "请输入",
            append: 'M',
            rules: [
              {
                required: true,
                message: "请输入上传附件大小",
                trigger: "blur",
              },
            ],
            annotation: "请配置附件上传的最大体积",
          },
        ],
        [
          {
            span: 24,
            type: "datetimerange",
            BindingValues: "timeLimit",
            BindingLabels: "使用时限",
            valueFormat: "x",
            startPlaceholder: "生效时间",
            endPlaceholder: "过期时间",
            rules: [
              {
                required: true,
                message: "请选择使用时限",
                trigger: "blur",
              },
            ],
            annotation: "请选择此应用开始生效的时间和失效的时间",
          },
        ],
        [
          {
            span: 12,
            type: "dropDownRadio",
            BindingValues: "system_class_id",
            BindingLabels: "应用分类",
            options: [],
            placeholder: "请选择",
            rules: [
              {
                required: true,
                message: "请选择应用分类",
                trigger: "blur",
              },
            ],
            annotation: "此应用需要放到哪个业务分类下？",
          },
          {
            span: 12,
            type: "dropDownMultiSelect",
            BindingValues: "system_type_id",
            BindingLabels: "上传文件类型",
            options: [],
            placeholder: "请选择",
            rules: [
              {
                required: true,
                message: "请选择允许上传的文件类型",
                trigger: "blur",
              },
            ],
            annotation: "允许此应用上传资源的类型，可多选",
          },
        ],
        [
          {
            span: 24,
            type: "inputPrepend",
            BindingValues: "system_app_url",
            BindingLabels: "允许使用地址",
            placeholder: "请输入",
            prepend: "Http://",
            rules: [
              {
                required: true,
                message: "应用允许使用的网址",
                trigger: "blur",
              },
            ],
            annotation: "设置需要使用此应用的程序网址。",
          },
        ],
      ],
      commonFromAnnotation: "请注意，此表单为一次行提交，保存之后不可再更改。",
    });

    function FnExportFrom(params: any) {
      console.log("FnExportFrom params ---->", params);
    }
    async function FnSaveFrom() {
      const res = await refsCommonFrom.value.FnExportFrom();
      if (res !== false) {
        res.system_app_start = res.timeLimit[0];
        res.system_app_end = res.timeLimit[1];
        res.system_type_id = res.system_type_id.join(",");
        delete res.timeLimit;
        console.log("FnSaveFrom ---->", res);

        FnCreatedAppApi(res);
      }
    }
    function FnApplicationNameBlur(event: any, params: any) {
      console.log("event ---->", event);
      console.log("params ---->", params);
    }
    async function FnGetEnum(val: string) {
      const params = {
        type: val,
      };
      const res = await mainServer.GetEnum(params, false);
      if (res.data.stateMsg === "success") {
        return res.data.data.res;
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }

    async function FnCreatedAppApi(item: any) {
      const res = await mainServer.CreatedAppApi(item, false);
      console.log("FnCreatedAppApi: res ---->", res);
      if (res.data.stateMsg === "success") {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "success");
        refsCommonFrom.value.FnReset();
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    onMounted(() => {
      FnGetEnum("system_class").then((res: any) => {
        data.commonFromConfig[5][0].options = res.map((val: any) => {
          val.label = val.system_class_name;
          val.value = val.system_class_id;
          return val;
        });
      });
      FnGetEnum("system_type").then((res: any) => {
        data.commonFromConfig[5][1].options = res.map((val: any) => {
          val.label = val.system_type_abbreviations;
          val.value = val.system_type_id;
          return val;
        });
      });
    });
    return {
      ...toRefs(data),
      proxy,
      FnExportFrom,
      refsCommonFrom,
      FnSaveFrom,
      FnGetEnum,
    };
  },
});
